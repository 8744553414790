export default function Privacy() {
  return (
    <main className="relative py-16 bg-gray-900 overflow-hidden">
      <section className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
        <section
          className="relative h-full text-lg max-w-prose mx-auto"
          aria-hidden="true"
        >
          <svg
            className="absolute top-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
            />
          </svg>
          <svg
            className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
            />
          </svg>
          <svg
            className="absolute bottom-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
            />
          </svg>
        </section>
      </section>
      <section className="relative px-4 sm:px-6 lg:px-8">
        <section className="text-lg max-w-prose mx-auto">
          <h2>
            <span className="block text-base text-center text-[color:var(--primary-font-color)] font-semibold tracking-wide uppercase">
              Legal
            </span>
            <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-white sm:text-4xl">
              Syncrosis Lab Privacy Policy
            </span>
          </h2>
          <p className="mt-8 text-lg text-gray-500">This Privacy Policy ("Policy") outlines how Syncrosis Lab Operates and Maintains its Code of Conduct</p>
          <h3 className="text-lg text-gray-500">1.Code of Conduct:</h3>
          <p className="text-lg text-gray-500">Defines acceptable behavior and ethics for employees</p>
          <h3 className="text-lg text-gray-500">2. Non-Disclosure Agreement (NDA): </h3>
          <p className="text-lg text-gray-500">Ensures confidentiality of proprietary information</p>
          {/* <ul className="text-lg text-gray-500">
            <li>To provide and improve our Instagram growth services, tailoring our strategies to your specific needs and objectives.</li>
            <li>To communicate with you, respond to your inquiries, and provide customer support.</li>
            <li>To analyze user behavior and engagement patterns to enhance our services and user experience.</li>
            <li>To send you promotional and marketing communications related to our services, provided you have opted to receive such communications.</li>
            <li>To ensure the security of our services and prevent fraudulent activities.</li>
          </ul> */}
          <h3 className="text-lg text-gray-500">3. Data Security:</h3>
          <p className="text-lg text-gray-500">We take data security seriously and implement measures to safeguard your personal information. However, no method of transmission over the internet or electronic storage is completely secure. While we strive to protect your data, we cannot guarantee absolute security.</p>
          <h3 className="text-lg text-gray-500">4. Third-Party Disclosures:</h3>
          <p className="text-lg text-gray-500">We do not sell, trade, or transfer your personal information to third parties without your consent. However, we may share your information with trusted third-party service providers who assist us in delivering our services. These third-party providers are obligated to maintain the confidentiality and security of your information.</p>
          <h3 className="text-lg text-gray-500">6. Changes to the Privacy Policy:</h3>
          <p className="text-lg text-gray-500">We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We will notify you of any material changes through the email associated with your account or by posting a prominent notice on our website. Your continued use of our services after such changes constitutes your acceptance of the revised Privacy Policy.</p>
          <h3 className="text-lg text-gray-500">7. Contact Us:</h3>
          <p className="text-lg text-gray-500">If you have any questions or concerns regarding this Privacy Policy or our services, please contact us at syncrosis.lab@gmail.com.</p>
        </section>
      </section>
    </main>
  );
}
