const cloudSolutionFeature = 
[
  {feature:"Continuous Integration (CI)/Continuous Deployment (CD)"},
  { feature:"Automated Testing"},
  {feature:"Version Control:"},
  {feature:"Infrastructure as Code (IaC):"},
  {feature:"Containerization"},
  {feature:"Orchestration"},
  {feature:"Monitoring and Logging:"},
  {feature:"Collaboration Tools"},
  {feature:"Security Integration"},
  {feature:"Configuration Management"},
  {feature:"Release Management"},
  {feature:"Pipeline Visualization"},
  {feature:"Environment Management:"},
  {feature:"Scalability:"},
  {feature:"Integration with Third-Party Tools:"},
  {feature:"Artifact Repository"},  
  
  ]

  export default cloudSolutionFeature