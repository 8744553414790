const mobileApplicationFeature = 
[
  {feature:"Cross-Platform Development"},
  { feature:"Native App Development"},
  {feature:"Custom Mobile App Development"},
  {feature:"User-Centric Design"},
  {feature:"Prototyping and Wireframing"},
  {feature:"Scalable Architecture"},
  {feature:"API Integration"},
  {feature:"Security Measures"},
  {feature:"Offline Functionality"},
  {feature:"Push Notifications"},
  {feature:"Continuous Integration and Continuous Deployment (CI/CD)"},
  {feature:"Performance Optimization"},
  {feature:"Testing and Quality Assurance"},
  {feature:"App Analytics and Reporting"},
  {feature:"Version Control and Maintenance"},
  {feature:"Compliance with App Store Guidelines"},   
  ]

  export default mobileApplicationFeature