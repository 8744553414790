import SyncrosisLogo from "../../assets/images/syncrosis.png";
import companyInformation from "../../data/companyInformation";
import syncrosisTeam from '../../assets/images/syn-team.jpeg'
export default function AboutOne() {
  return (
    <article className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start max-w-7xl mx-auto">
      <section className="relative sm:py-16 lg:py-0">
        <section
          aria-hidden="true"
          className="hidden sm:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-screen"
        >
          <section className="absolute inset-y-0 right-1/2 w-full bg-indigo-500 rounded-r-3xl lg:right-72 opacity-50" />
          <svg
            className="absolute top-8 left-1/2 -ml-3 lg:-right-8 lg:left-auto lg:top-12"
            width={404}
            height={392}
            fill="none"
            viewBox="0 0 404 392"
          >
            <defs>
              <pattern
                id="02f20b47-fd69-4224-a62a-4c9de5c763f7"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={392}
              fill="url(#02f20b47-fd69-4224-a62a-4c9de5c763f7)"
            />
          </svg>
        </section>
        <section className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-20">
          {/* Testimonial card*/}
          <section className="relative pt-64 pb-10 rounded-2xl shadow-xl overflow-hidden">
            <img
              className="absolute inset-0 h-full w-full object-cover"
              src={syncrosisTeam}
              alt=""
              aria-hidden="true"
            />
            <section className="absolute inset-0 bg-indigo-500 mix-blend-multiply opacity-30" />
            <section className="absolute inset-0 bg-gradient-to-t from-indigo-600 via-indigo-600 opacity-90" />
            <section className="relative px-8">
              <section>
                <img className="h-12" src={SyncrosisLogo} alt="Syncrosis Lab" />
              </section>
              <blockquote className="mt-8">
                {/* <section className="relative text-lg font-medium text-white md:flex-grow">
                  <svg
                    className="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-[color:var(--primary-font-color)]"
                    fill="currentColor"
                    viewBox="0 0 32 32"
                    aria-hidden="true"
                  >
                    <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                  </svg>
                   <p className="relative">
                    In Syncrosis Lab, We firmly believe in the transformative power of software development. In the digital age, where innovation is the currency of progress, our commitment lies in harnessing the capabilities of code to drive unparalleled advancements. Software is not merely a tool; it's the catalyst that propels industries forward, empowers businesses to thrive, and transforms ideas into reality. At Syncrosis Lab, we view each line of code as a potential game-changer, and our mission is to sculpt the future of technology with precision, creativity, and the unwavering belief that software has the unparalleled ability to shape a better tomorrow.
                  </p> 
                </section> */}
                <section className="mt-4">
                  {/* <p className="text-base font-semibold text-indigo-200">
                    Catherine Black, CEO at Edgy
                  </p> */}
                </section>
              </blockquote>
            </section>
          </section>
        </section>
      </section>

      <section className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0">
        <section className="pt-12 sm:pt-16 lg:pt-20">
          <h2 className="text-3xl text-white font-extrabold tracking-tight sm:text-4xl">
            On a mission to empower teams
          </h2>
          <section className="mt-6 text-gray-500 space-y-6">
            <p className="text-base leading-7">
            Step into a realm of endless innovation and unparalleled solutions. At Syncrosis Lab, we're more than just a software company; we're the catalysts for your technological evolution. Embrace the future with us and discover the boundless capabilities of Syncrosis Lab – where every solution is a step towards your digital excellence."

           </p>
            <p className="text-base leading-7">
            At Syncrosis Lab, our overarching goal is to pioneer innovation in the realm of software development. Committed to pushing the boundaries of technology, we aspire to create cutting-edge solutions that not only meet but exceed the evolving needs of our clients. Through a harmonious blend of expertise, creativity, and a relentless pursuit of excellence, we aim to be at the forefront of transformative software solutions. Whether it's crafting user-friendly applications or engineering robust systems, our mission is to empower businesses and individuals alike by delivering software that not only meets current industry standards but sets new benchmarks for the future
            </p>
          </section>
        </section>
        {/* Stats section */}
        <section className="mt-10">
          <dl className="grid grid-cols-2 gap-x-4 gap-y-8">
            {companyInformation.map((stat) => (
              <section
                key={stat.label}
                className="border-t-2 border-gray-100 pt-6"
              >
                <dt className="text-base font-medium text-gray-500">
                  {stat.label}
                </dt>
                <dd className="text-3xl font-extrabold tracking-tight text-white">
                  {stat.value}
                </dd>
              </section>
            ))}
          </dl>
        </section>
      </section>
    </article>
  )
}