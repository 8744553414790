export const api_endpoints={
    get_faqs:"faq/get-all",
    get_jobs:"",
    get_blogs:"blogs/get-all",
    get_blog:"blogs/get",
    get_products:"",
    get_product:"",
    contact_us:"contact/add",
    newsletter:"newsletter/add"

}
