const companyStats = [
    {
        id: 1,
        stat: '1K+',
        title: 'Number of Happy Users',
        desc: 'and they are becoming more happy',
    },
    {
        id: 2,
        stat: '10+',
        title: 'Organisations are Provided with Service',
        desc: 'and they never stop coming',
    },
    {
        id: 3,
        stat: '+150%',
        title: 'Increased Engagement',
        desc: 'Boosting Buisiness Growth',
    },
    {
        id: 4,
        stat: '+33%',
        title: 'Increased Leads',
        desc: 'the business keeps getting better',
    },
]

export default companyStats;