const WebApplicationFeature = 
[
  {feature:"User-Friendly Design"},
  { feature:"Mobile Compatibility:"},
  {feature:"Fast Loading Speed"},
  {feature:"Interactive Elements"},
  {feature:"Customized Solutions"},
  {feature:"Scalable Architecture"},
  {feature:"Reliable Performance"},
  {feature:"Secure Transactions"},
  {feature:"Search Engine Optimization (SEO)"},
  {feature:"Social Media Integration"},
  {feature:"Content Management"},
  {feature:"Support and Maintenance"},
  {feature:"Clear Calls-to-Action"},
  {feature:"Visual Appeal"},
  {feature:"Cross-Browser Compatibility"},
  {feature:"Easy Contact Options"},  
  {feature:"Loading Time Optimization"},
  {feature:"Regular Updates"}, 
  {feature:"Transparent Pricing"},
  {feature:"Educational Resources"},
  ]

  export default WebApplicationFeature