const jobs = [
    {
        department: 'Human Resource Management',
        role: 'HR Assistant',
        imageUrl:
            'https://images.unsplash.com/photo-1571844307880-751c6d86f3f3?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1896&q=80',
        desc:
            'Join our dynamic team as an HR Assistant! We are looking for an organized and proactive individual to support our HR department in various administrative tasks. If you have strong communication skills, attention to detail, and a passion for helping others, this role is perfect for you. Apply now and contribute to fostering a positive work environment and employee satisfaction.',
    },
    {
        department: 'Development',
        role: 'Software Engineer(Go)',
        imageUrl:
            'https://images.unsplash.com/photo-1542744173-8e7e53415bb0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80',
        desc:
            "As a Software Engineer specializing in Golang, you'll architect robust, scalable systems leveraging Go's concurrency and performance features. Your role involves designing and implementing backend services, APIs, and microservices, ensuring optimal performance and reliability. Collaborating with cross-functional teams, you'll pioneer solutions, optimize codebase, and contribute to the evolution of high-performance applications. Join us to shape the future of software development with Golang expertise."
    },
    {
        department: 'Development',
        role: 'Junior Software Engineer(React)',
        imageUrl:
            'https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1772&q=80',
        desc:
            'Are you an experienced React Developer looking for new challenges? Join our innovative tech team as a Senior React Developer and play a key role in building cutting-edge web applications. Bring your expertise in React, JavaScript, and web development to lead projects, mentor junior developers, and contribute to our exciting software solutions.',
    },
    {
        department: 'Customer Support',
        role: 'Junior Customer Support',
        imageUrl:
            'https://images.unsplash.com/photo-1600880292203-757bb62b4baf?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2940&q=80',
        desc:
            'Kickstart your career in customer support with our Junior Customer Support position. If you have excellent communication skills, a friendly demeanor, and a desire to assist customers, this role is an ideal fit. You will be responsible for resolving inquiries, providing product information, and ensuring customer satisfaction. Grow with us and gain valuable experience in the world of customer service.',
    },
]
export default jobs;