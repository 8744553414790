export default function Terms() {
  return (
    <main className="relative py-16 bg-gray-900 overflow-hidden">
      <section className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
        <section
          className="relative h-full text-lg max-w-prose mx-auto"
          aria-hidden="true"
        >
          <svg
            className="absolute top-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
            />
          </svg>
          <svg
            className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
            />
          </svg>
          <svg
            className="absolute bottom-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
            />
          </svg>
        </section>
      </section>
      <section className="relative px-4 sm:px-6 lg:px-8">
        <section className="text-lg max-w-prose mx-auto">
          <h2>
            <span className="block text-base text-center text-[color:var(--primary-font-color)] font-semibold tracking-wide uppercase">
              Legal
            </span>
            <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-white sm:text-4xl">
              Syncrosis Lab Terms & Conditions
            </span>
          </h2>
          <p className="mt-8 text-lg text-gray-500">These Terms & Conditions ("Terms") govern your use of the services provided by Syncrosis Labs ("Syncrosis Labs," "we," "our," or "us"). By using our services, you ("you," "your," or "user") agree to comply with these Terms and enter into a binding agreement with Syncrosis Labs. If you do not agree with these Terms, please refrain from using our services.</p>
<h3 className="text-lg text-gray-500">1. Account Access:</h3>
<p className="text-lg text-gray-500">To utilize our services, you grant Syncrosis Labs limited access as necessary. This access is essential for our team to perform manual engagement and implement growth strategies. We do not store or share your account login information, and we handle your data with utmost confidentiality.</p>
<h3 className="text-lg text-gray-500">2. Service Eligibility:</h3>
<p className="text-lg text-gray-500">You must be at least 18 years old and possess the legal authority to enter into a contract to use our services. By using our services, you represent and warrant that you meet these eligibility requirements.</p>
<h3 className="text-lg text-gray-500">3. Prohibited Activities:</h3>
<p className="text-lg text-gray-500">You agree not to use our services for any illegal, unethical, or unauthorized purposes. Prohibited activities include, but are not limited to, spamming, using bots or automation, engaging in fraudulent activities, and violating intellectual property rights.</p>
<h3 className="text-lg text-gray-500">4. Service Results:</h3>
<p className="text-lg text-gray-500">While we strive to deliver significant growth and engagement, we do not guarantee specific outcomes. Results may vary based on factors such as your content, target audience, and account history. Our growth strategies focus on building meaningful connections.</p>
<h3 className="text-lg text-gray-500">5. Intellectual Property:</h3>
<p className="text-lg text-gray-500">All intellectual property rights associated with our services are owned by Syncrosis Labs. You are not allowed to use, copy, or distribute our intellectual property without our express written consent.</p>
<h3 className="text-lg text-gray-500">6. Termination:</h3>
<p className="text-lg text-gray-500">You have the right to terminate your use of our services at any time. We reserve the right to terminate or suspend your access to our services, with or without cause, at our discretion. In the event of termination, any fees paid are non-refundable.</p>
<h3 className="text-lg text-gray-500">7. Disclaimer of Warranties:</h3>
<p className="text-lg text-gray-500">Our services are provided "as is" and without any warranties or guarantees. Syncrosis Labs does not warrant that our services will be uninterrupted, error-free, or free of viruses or other harmful components. We disclaim all warranties, whether express or implied, including but not limited to the implied warranties of merchantability and fitness for a particular purpose.</p>
<h3 className="text-lg text-gray-500">8. Limitation of Liability:</h3>
<p className="text-lg text-gray-500">To the maximum extent permitted by law, Syncrosis Labs shall not be liable for any direct, indirect, incidental, special, consequential, or punitive damages arising from or related to the use of our services.</p>
<h3 className="text-lg text-gray-500">9. Indemnification:</h3>
<p className="text-lg text-gray-500">You agree to indemnify and hold Syncrosis Labs harmless from any claims, losses, liabilities, damages, expenses, and costs (including attorney's fees) arising from your use of our services or your violation of these Terms.</p>
<h3 className="text-lg text-gray-500">10. Modifications:</h3>
<p className="text-lg text-gray-500">Syncrosis Labs reserves the right to update or modify these Terms at any time without prior notice. Your continued use of our services after any changes to the Terms constitutes your acceptance of the revised Terms.</p>
<h3 className="text-lg text-gray-500">11. Governing Law:</h3>
<p className="text-lg text-gray-500">These Terms shall be governed by and construed in accordance with the laws of [Jurisdiction], without regard to its conflict of laws principles.</p>
<h3 className="text-lg text-gray-500">12. Contact Us:</h3>
<p className="text-lg text-gray-500">If you have any questions or concerns regarding these Terms or our services, please contact us at support@syncrosislabs.com.</p>
<p className="text-lg text-gray-500">By using Syncrosis Labs' services, you acknowledge that you have read, understood, and agree to these Terms & Conditions.</p>

        </section>
      </section>
    </main>
  );
}
