import PhoneIcon from '../assets/images/customer-support.png'
import SecurityIcon from '../assets/images/security.png'
import FollowerIcon from '../assets/images/followers.png'
import ContentIcon from '../assets/images/content-management.png'
import TargetIcon from '../assets/images/target-audience.png'
import PlanIcon from '../assets/images/payment-plan.png'

const features = [
    {
        name: 'Web Solutions',
        description:
            'Our team of experienced professionals specializes in delivering scalable and efficient web solutions, ensuring seamless user experiences and optimal performance.',
        icon: PlanIcon,
    },
    {
        name: 'Custom Software Development:',
        description:
            'We offer Enterprise resource planning (ERP) systems, customer relationship management (CRM) software, and industry-specific applications.',
        icon: TargetIcon,
    },
    {
        name: 'Mobile App Development',
        description:
            'We Offer Design and development of applications for mobile devices (iOS, Android, or cross-platform).',
        icon: ContentIcon,
    },
    {
        name: 'Cloud Computing Solutions',
        description:
            'We Offer different types of Cloud Computing Solutions Including cloud migration, infrastructure as a service (IaaS), platform as a service (PaaS), and software as a service (SaaS).',
        icon: FollowerIcon,
    },
    {
        name: 'Cybersecurity Solutions',
        description:
            'We Offer Offering security software and services to protect against cyber threats including vulnerability assessments, penetration testing, and development of security protocols',
        icon: SecurityIcon,
    },

    {
        name: 'Quality Assurance and Testing',
        description:
            'We Ensure software reliability through testing and quality assurance services.',
        icon: PhoneIcon,
    },
    {
        name: 'UI/UX Design Services',
        description:
            'We Offer Designs for intuitive and user-friendly interfaces for software applications',
        icon: PhoneIcon,
    },
    {
        name: 'E-commerce Solutions',
        description:
            'We Offer Development of online shopping platforms and e-commerce websites Includeing features such as payment gateways, inventory management, and order processing.',
        icon: PhoneIcon,
    }
]

export default features;



