import synInventory from '../assets/images/tandb.png'
import tripPortal from '../assets/images/tp.png'
import ecommerce from '../assets/images/ecom.png'
const porfolioData = [
    {
        name: "Taluker and Brothers Inventory",
        duration: [
            { label: "Timeline", value: "2021" },
            { label: "Client", value: "Talukder and Brothers" },
        ],
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tincidunt, justo eget convallis sollicitudin, tortor velit tincidunt dui, et varius metus turpis in arcu. Integer dapibus, augue ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus. Integer dictum gravida augue, et efficitur nulla fermentum ac. Quisque sit amet justo vel quam feugiat convallis a vel sapien. Sed ut ipsum euismod, tincidunt erat vel, tristique purus. Vestibulum vitae justo ac risus vestibulum aliquet. Maecenas ut mi et quam consequat pulvinar. Suspendisse potenti. Sed vel enim et nulla vehicula elementum. Nullam bibendum dapibus est, nec semper neque fermentum nec. Etiam eget risus eu risus tristique rhoncus nec quis velit.In hac habitasse platea dictumst. Integer at felis a eros tincidunt malesuada. Nullam eu urna ac elit aliquam fermentum. Fusce varius urna ac eros dapibus, vel ultricies neque elementum. Mauris aliquet lacus eu eros scelerisque, in dapibus nisi sollicitudin. Duis malesuada mauris ut lectus aliquam, eu auctor purus efficitur. Nulla facilisi. In hac habitasse platea dictumst. Nunc tincidunt dui vel libero aliquet, et cursus tortor vestibulum. Suspendisse potenti. Integer consectetur erat id justo malesuada fermentum. Sed tincidunt metus nec risus aliquet bibendum. Curabitur ac odio nec justo sodales cursus. Vivamus nec ante sed urna cursus efficitur. Sed euismod, libero nec dignissim dictum, turpis metus tincidunt odio, sit amet fermentum purus ligula vitae odio.",
        image: synInventory
    
    },
    {
        name: "Trip Portal BD",
        duration: [
            { label: "Timeline", value: "2021" },
            { label: "Client", value: "Trip Portal BD" },
        ],
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tincidunt, justo eget convallis sollicitudin, tortor velit tincidunt dui, et varius metus turpis in arcu. Integer dapibus, augue ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus. Integer dictum gravida augue, et efficitur nulla fermentum ac. Quisque sit amet justo vel quam feugiat convallis a vel sapien. Sed ut ipsum euismod, tincidunt erat vel, tristique purus. Vestibulum vitae justo ac risus vestibulum aliquet. Maecenas ut mi et quam consequat pulvinar. Suspendisse potenti. Sed vel enim et nulla vehicula elementum. Nullam bibendum dapibus est, nec semper neque fermentum nec. Etiam eget risus eu risus tristique rhoncus nec quis velit.In hac habitasse platea dictumst. Integer at felis a eros tincidunt malesuada. Nullam eu urna ac elit aliquam fermentum. Fusce varius urna ac eros dapibus, vel ultricies neque elementum. Mauris aliquet lacus eu eros scelerisque, in dapibus nisi sollicitudin. Duis malesuada mauris ut lectus aliquam, eu auctor purus efficitur. Nulla facilisi. In hac habitasse platea dictumst. Nunc tincidunt dui vel libero aliquet, et cursus tortor vestibulum. Suspendisse potenti. Integer consectetur erat id justo malesuada fermentum. Sed tincidunt metus nec risus aliquet bibendum. Curabitur ac odio nec justo sodales cursus. Vivamus nec ante sed urna cursus efficitur. Sed euismod, libero nec dignissim dictum, turpis metus tincidunt odio, sit amet fermentum purus ligula vitae odio.",
        image: tripPortal
    
    },
    {
        name: "Shema Ecommerce",
        duration: [
            { label: "Timeline", value: "2021" },
            { label: "Client", value: "Shema" },
        ],
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tincidunt, justo eget convallis sollicitudin, tortor velit tincidunt dui, et varius metus turpis in arcu. Integer dapibus, augue ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus. Integer dictum gravida augue, et efficitur nulla fermentum ac. Quisque sit amet justo vel quam feugiat convallis a vel sapien. Sed ut ipsum euismod, tincidunt erat vel, tristique purus. Vestibulum vitae justo ac risus vestibulum aliquet. Maecenas ut mi et quam consequat pulvinar. Suspendisse potenti. Sed vel enim et nulla vehicula elementum. Nullam bibendum dapibus est, nec semper neque fermentum nec. Etiam eget risus eu risus tristique rhoncus nec quis velit.In hac habitasse platea dictumst. Integer at felis a eros tincidunt malesuada. Nullam eu urna ac elit aliquam fermentum. Fusce varius urna ac eros dapibus, vel ultricies neque elementum. Mauris aliquet lacus eu eros scelerisque, in dapibus nisi sollicitudin. Duis malesuada mauris ut lectus aliquam, eu auctor purus efficitur. Nulla facilisi. In hac habitasse platea dictumst. Nunc tincidunt dui vel libero aliquet, et cursus tortor vestibulum. Suspendisse potenti. Integer consectetur erat id justo malesuada fermentum. Sed tincidunt metus nec risus aliquet bibendum. Curabitur ac odio nec justo sodales cursus. Vivamus nec ante sed urna cursus efficitur. Sed euismod, libero nec dignissim dictum, turpis metus tincidunt odio, sit amet fermentum purus ligula vitae odio.",
        image: ecommerce
    
    },
    {
        name: "Pakhshi Resort",
        duration: [
            { label: "Timeline", value: "2021" },
            { label: "Client", value: "Pakhshi Resort" },
        ],
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tincidunt, justo eget convallis sollicitudin, tortor velit tincidunt dui, et varius metus turpis in arcu. Integer dapibus, augue ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus. Integer dictum gravida augue, et efficitur nulla fermentum ac. Quisque sit amet justo vel quam feugiat convallis a vel sapien. Sed ut ipsum euismod, tincidunt erat vel, tristique purus. Vestibulum vitae justo ac risus vestibulum aliquet. Maecenas ut mi et quam consequat pulvinar. Suspendisse potenti. Sed vel enim et nulla vehicula elementum. Nullam bibendum dapibus est, nec semper neque fermentum nec. Etiam eget risus eu risus tristique rhoncus nec quis velit.In hac habitasse platea dictumst. Integer at felis a eros tincidunt malesuada. Nullam eu urna ac elit aliquam fermentum. Fusce varius urna ac eros dapibus, vel ultricies neque elementum. Mauris aliquet lacus eu eros scelerisque, in dapibus nisi sollicitudin. Duis malesuada mauris ut lectus aliquam, eu auctor purus efficitur. Nulla facilisi. In hac habitasse platea dictumst. Nunc tincidunt dui vel libero aliquet, et cursus tortor vestibulum. Suspendisse potenti. Integer consectetur erat id justo malesuada fermentum. Sed tincidunt metus nec risus aliquet bibendum. Curabitur ac odio nec justo sodales cursus. Vivamus nec ante sed urna cursus efficitur. Sed euismod, libero nec dignissim dictum, turpis metus tincidunt odio, sit amet fermentum purus ligula vitae odio.",
        image: ecommerce
    
    }
]
export default porfolioData