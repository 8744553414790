export default function AboutThree() {
  return (
    <article className="relative bg-gray-900">
      <section className="lg:absolute lg:inset-0 max-w-7xl mx-auto">
        <section className="lg:absolute lg:inset-y-0 lg:left-0 lg:w-1/2">
          <img
            className="h-56 w-full object-cover lg:absolute lg:h-full object-center"
            src="https://images.unsplash.com/photo-1600880292203-757bb62b4baf?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80"
            alt=""
            aria-hidden="true"
          />
        </section>
      </section>

      <section className="relative pt-12 pb-16 px-4 sm:pt-16 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2 ">
        <section className="lg:col-start-2 lg:pl-8 ">
          <section className="text-base max-w-prose mx-auto lg:max-w-lg lg:ml-auto lg:mr-0 ">
            <h2 className="leading-6 text-[color:var(--primary-font-color)] font-semibold tracking-wide uppercase">
              Collaborate
            </h2>
            <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-white sm:text-4xl">
              Let's work together
            </h3>
            <p className="mt-8 text-lg text-gray-500">
            In the dynamic landscape of technological advancement, choosing the right partner for software development is a pivotal decision that can shape the trajectory of a business or individual venture. Enter Syncrosis Lab, a cutting-edge software development company founded in 2021, poised to redefine industry standards and revolutionize the digital experience. Collaborating with Syncrosis Lab isn't just a business transaction; it's an investment in innovation, reliability, and a partnership that propels your vision to new heights.
            </p>
            <section className="mt-5 prose prose-indigo text-gray-500">
              <p className="mt-8 text-lg text-gray-500">
              </p>
              <p className="mt-8 text-lg text-gray-500">
              First and foremost, Syncrosis Lab stands out as a beacon of fresh ideas and innovation. Founded in 2021, our youth is our strength, allowing us to seamlessly blend modern trends with a forward-thinking approach. We embrace the latest technologies, methodologies, and industry best practices, ensuring that our clients are at the forefront of technological evolution. With a team of passionate and skilled professionals, Syncrosis Lab brings a fresh perspective and an unbridled enthusiasm to every project.
              </p>
              <p className="mt-8 text-lg text-gray-500">
              Our commitment to excellence is not just a slogan; it's ingrained in our DNA. Syncrosis Lab is dedicated to delivering software solutions that not only meet but exceed expectations. We understand that in a world driven by user experience, functionality, and reliability are non-negotiable. Our rigorous quality assurance processes ensure that every line of code is a testament to our commitment to excellence, resulting in software that stands the test of time and performs flawlessly.
              </p>
              <p className="mt-8 text-lg text-gray-500">
              Collaborating with Syncrosis Lab also means gaining access to a versatile range of services tailored to meet the unique needs of clients at any stage of their journey. Whether you are a startup seeking to establish a digital footprint, an established enterprise aiming to optimize operations, or an individual with a groundbreaking idea, Syncrosis Lab has the expertise to turn concepts into reality. Our solutions are scalable, customizable, and designed to evolve with your business.
              </p>
              <p className="mt-8 text-lg text-gray-500">
              </p>
              <p className="mt-8 text-lg text-gray-500">
              In an era where time is of the essence, Syncrosis Lab offers unparalleled agility and responsiveness. Our development processes are streamlined to ensure timely delivery without compromising on quality. We understand that in the fast-paced world of technology, being the first to market can make all the difference. Syncrosis Lab is your trusted partner in turning ideas into reality swiftly and efficiently.
              </p>
              <p className="mt-8 text-lg text-gray-500">
              Furthermore, Syncrosis Lab prides itself on transparent communication and collaboration. We believe in forging strong partnerships with our clients, understanding their vision, and working together towards shared goals. Our client-centric approach ensures that your feedback is not only valued but actively incorporated into the development process, resulting in solutions that resonate with your objectives.
              </p>
              <p className="mt-8 text-lg text-gray-500">
              In conclusion, collaborating with Syncrosis Lab for software development is not just a choice; it's a strategic investment in innovation, reliability, and success. Our commitment to pushing the boundaries of technology, coupled with our fresh perspective, dedication to excellence, versatile services, agility, and transparent collaboration, positions Syncrosis Lab as the ideal partner for turning your software development aspirations into reality. Embrace the future of technology with Syncrosis Lab, where innovation knows no bounds.
              </p>
            </section>
          </section>
        </section>
      </section>
    </article>
  )
}