import { Link } from "react-router-dom";

export default function AboutTwo() {
  const ResetLocation = () => window.scrollTo(0, 0);
  return (
    <article className="py-16 xl:py-36 px-4 sm:px-6 lg:px-8 bg-gray-900 overflow-hidden">
      <section className="max-w-max lg:max-w-7xl mx-auto">
        <section className="relative z-10 mb-8 md:mb-2 md:px-6 ">
          <section className="text-base max-w-prose lg:max-w-none ">
            <h2 className="leading-6 text-[color:var(--primary-font-color)] font-semibold tracking-wide uppercase">
              We help to Grow Your Buisiness
            </h2>
            <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-white sm:text-4xl">
            Expand your Buisiness through our Cutting-edge Software Automation Strategies.
            </p>
          </section>
        </section>
        <section className="relative">
          <svg
            className="hidden md:block absolute top-0 right-0 -mt-20 -mr-20"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="95e8f2de-6d30-4b7e-8159-f791729db21b"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#95e8f2de-6d30-4b7e-8159-f791729db21b)"
            />
          </svg>
          <svg
            className="hidden md:block absolute bottom-0 left-0 -mb-20 -ml-20"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="7a00fe67-0343-4a3c-8e81-c145097a3ce0"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#7a00fe67-0343-4a3c-8e81-c145097a3ce0)"
            />
          </svg>
          <section className="relative md:bg-gray-900 md:p-6">
            <section className="lg:grid lg:grid-cols-2 lg:gap-6">
              <section className="prose prose-indigo prose-lg text-gray-500 lg:max-w-none">
                <p>
                Syncrosis Lab is dedicated to catalyzing the growth of startups through our tailored software solutions. As a startup, navigating the intricate landscape of technology can be daunting, but with Syncrosis Lab as your technology partner, we offer a roadmap to success. Our team of seasoned professionals understands the unique challenges startups face, and we leverage our expertise to create scalable and innovative software solutions that align with your business goals. Whether it's developing a user-friendly app, optimizing workflow automation, or implementing robust cybersecurity measures, Syncrosis Lab is committed to providing startups with the technological foundation they need for sustained growth.
                </p>
              </section>
              <section className="mt-6 prose prose-indigo prose-lg text-gray-500 lg:mt-0">
                <p>
                What sets Syncrosis Lab apart is our holistic approach to startup collaboration. We don't just deliver software solutions; we become an integral part of your growth journey. By fostering open communication and a collaborative spirit, we ensure that our solutions evolve with your changing needs. Whether you're in the early stages of development or seeking to scale rapidly, Syncrosis Lab's adaptive and comprehensive software services are designed to be the catalyst for your startup's success, providing the technological backbone necessary to thrive in today's competitive business landscape.
                </p>
              </section>
            </section>
            <section className="mt-8 inline-flex rounded-md shadow">
              <Link
                onClick={ResetLocation}
                to="/contact"
                className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
              >
                Contact us
              </Link>
            </section>
          </section>
        </section>
      </section>
    </article>
  )
}