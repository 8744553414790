import django from '../assets/images/django.png'
import JS from '../assets/images/js.png'
import node from '../assets/images/node.png'
import android from '../assets/images/andriod.png'
import ios from '../assets/images/ios.png'
const partners = [
    {
        id: 0,
        name: 'Django',
        img: django,
        href: 'https://www.djangoproject.com/'
    },
    {
        id: 1,
        name: 'Java Script',
        img: JS,
        href: 'https://javascript.info/'
    },
    {
        id: 2,
        name: 'Node',
        img: node,
        href: 'https://nodejs.org/en'
    },
    {
        id: 3,
        name: 'Android',
        img: android,
        href: 'https://developer.android.com/'
    },
    {
        id: 4,
        name: 'IOS',
        img: ios,
        href: 'https://www.swift.com/'
    },
]

export default partners