import PortfolioCards from '../../components/portfolio-cards/cards'
import porfolioData from '../../data/portfolio-data'
export default function Portfolio() {
    return (
      <main className="relative bg-gray-900 py-16 sm:py-24">
          <div>
              {
                  porfolioData.map((data,index)=>{
                   return(
                    <PortfolioCards key={index} data={data}/>
                   ) 
                  })
              }
          
          </div>
      </main>
    );
  }
  