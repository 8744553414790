// import {base_url} from '../endpoints/index'
import {api_endpoints} from '../endpoints/endpoints'
import axios from 'axios'
const base_url="https://api.syncrosislab.com/api/syncrosis/"
export async function getAllFaq(){
    const res = await axios.get(`${base_url}${api_endpoints.get_faqs}`)
    return res.data
}
export async function getAllBlogs(){
    const res = await axios.get(`${base_url}${api_endpoints.get_blogs}`)
    return res.data
}
export async function getBlogsByID(ID){
    const res = await axios.get(`${base_url}${api_endpoints.get_blog}/${ID}`)
    return res.data
}

export async function submitEmailForNewsLetter(data){
    const res = await axios.post(`${base_url}${api_endpoints.newsletter}`,data)
    return res
}
export async function postContactInfo(data){
    const res = await axios.post(`${base_url}${api_endpoints.contact_us}`,data)
    return res
}